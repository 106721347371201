<template>
    <div class="budget">
        <h1 >{{ $t('titles.budget_view') }}</h1>
        <LoadedContent  :loaded="isLoaded && isLoadedOrganizations" :error="isError || isErrorOrganizations">
            <BaseTable :columns="columns" :data="data" :rowClass="rowClass" />
        </LoadedContent>
    </div>
    
</template>

<script>
import LoadedContent from '@common/components/base/LoadedContent';
import { useStore } from 'vuex';
import { computed, onUnmounted, provide } from 'vue';
import BaseTable from "@common/components/crud/BaseTable";
export default {
    name: "Budget",
    components: {
        BaseTable,
        LoadedContent,
    },
    setup(){
        provide("crud_store","budget")

        const store = useStore();

        const loadData = () => {
            store.dispatch('organization/fetchItems',{});
            store.dispatch('budget/fetchItems',{});
        };  
        loadData();
        const interval = setInterval(loadData, 300000);
        
        onUnmounted(() => {
            clearInterval(interval);
        })

        // getting data
        const data = computed(() => store.getters['budget/getItems']);

        const isLoaded = computed(() => store.getters["budget/isLoaded"]);
        
        const isError = computed(() => store.getters["budget/isError"]);
        
        const organizations = computed(() => store.getters['organization/getItems']);

        const isLoadedOrganizations = computed(() => store.getters["organization/isLoaded"]);
        
        const isErrorOrganizations = computed(() => store.getters["organization/isError"]);

        const getOrganizationByKey = (key) => {
            for(let organization of organizations.value){
                if(organization.item_key === key){
                    return organization;
                }
            }
            return null;
        }

        const getPercentage = (record) => {
            if(!record.ks_P3 || parseInt(record.ks_P3) < 0.1){
                return "-";
            }
            return Math.round((parseInt(record.ksSkut_P3)/parseInt(record.ks_P3)+ Number.EPSILON)*1000)/10;
        }
        
        const columns = [
            { 
                type: "info",
                header: "období",
                key: "obdobi",
                title: "období",
                class: "",
            },
            { 
                type: "label",
                header: " org. ičo",
                title: "ičo",
                class: "",
                content: (record) => {
                    let org = getOrganizationByKey(record.zn_URorg);
                    if(org){
                        return org.ico;
                    }
                    return '';
                }
            },
            { 
                type: "info",
                header: "org.",
                key: "zn_URorg",
                title: "organizace",
                class: 'w-s',
                filter: true,
                filter_exact: true,
            },
            { 
                type: "info",
                header: "N/V",
                key: "NV",
                title: "N/V",
                class: 'w-xs',
                filter: true,
                filter_exact: true,
            },
            { 
                type: "info",
                header: "složka (MÚ)",
                key: "muoSlozka",
                title: "složka (MÚ)",
                class: 'w-xxl align-left',
                filter: true,
                filter_exact: true,
            },
            { 
                type: "info",
                header: "Schválený rozpis rozpočtu",
                key: "ksSchval_P3",
                title: "Schválený rozpis rozpočtu",
                class: 'align-right w-l',
            },
            { 
                type: "info",
                header: "Upravený rozpis rozpočtu",
                key: "ks_P3",
                title: "Upravený rozpis rozpočtu",
                class: 'align-right w-l',
            },
            { 
                type: "info",
                header: "Skutečnost",
                key: "ksSkut_P3",
                title: "Skutečnost",
                class: 'align-right w-l',
            },
            { 
                type: "label",
                header: "[%]",
                title: "[%]",
                class: "w-s",
                content: (record) => {
                    return getPercentage(record);
                }
            },
            { 
                type: "label",
                header: "",
                title: "",
                class: "w-s strong mark",
                // getClass: (record) => {
                //     return getPercentage(record) <= 100 ? 'success' : "danger";
                // },
                content: (record) => {
                    return getPercentage(record) < 100 ? '<' : getPercentage(record) == 100 ? '=' : ">";
                }
            },
        ];

        const rowClass = (record) => {
            let diff = parseInt(record.ksSkut_P3)-parseInt(record.ks_P3);
            if(record.NV === 'V'){
                return diff >= -1 ? 'success' : "danger";
            }
            return diff <= 1 ? 'success' : "danger";
        }

        return {
            columns,
            data,
            isLoaded,
            isError,
            organizations,
            isLoadedOrganizations,
            isErrorOrganizations,
            rowClass
        }
    }
}
</script>

<style scoped>
.budget,.loaded-content{
    display: flex;
    flex-direction: column;
    height: 100%;
}
::v-deep(.base-table){
    margin: 1rem;
    border-collapse: separate;
    border-spacing: 0 0.2rem;
    min-width: initial;
}

::v-deep(.base-table-row:nth-child(odd).success td) {
    background: #f0ffe4;
}

::v-deep(.base-table-row:nth-child(even).success td) {
    background: #daffbb;
}
::v-deep(.base-table-row:nth-child(odd).danger td) {
    background: #ffdfda;
}

::v-deep(.base-table-row:nth-child(even).danger td) {
    background: #ffc5bb;
}
::v-deep(.base-table-row td) {
    padding: 0.5rem 0.2rem;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
}
::v-deep(.base-table-row:nth-child(even).danger td) {
    border-right: 1px solid rgb(233, 233, 233);
}
::v-deep(.base-table-row td>*) {
    height: initial;
}

::v-deep(.base-table-header th) {
    padding: 0.5rem;
}
::v-deep(.base-table td:first-child) { 
    border-top-left-radius: 1rem; 
    border-bottom-left-radius: 1rem;
    padding: 0.5rem 0.2rem 0.5rem 0.5rem;
}
::v-deep(.base-table td:last-child) { 
    border-top-right-radius: 1rem; 
    border-bottom-right-radius: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 0.2rem;
}

::v-deep(.base-table-row .success) {
    font-weight: 700;
    background: green
}
::v-deep(.base-table-row .danger) {
    font-weight: 700;
    color: red
}
::v-deep(.mark .base-table-cell-label) {
    font-size: 1.2rem;
    color: #177542;
}
</style>